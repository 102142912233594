import styled from 'styled-components'

const Figcaption = styled.figcaption`
  color: ${(p) => p.theme.colors.grey};
  font-size: 14px;
  text-align: center;
  width: 100%;
  padding-top: 6px;
  padding-bottom: 20px;
  line-height: 2em;
`

export default Figcaption
