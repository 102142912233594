import * as React from 'react'
import styled from 'styled-components'
import Button from './Button'
import mediaqueries from '../../styles/media'

const BookingButton = styled(Button)`
  box-sizing: border-box;
  text-decoration: none;
  font-weight: ${(p) => p.theme.fonts.weights.heavy};
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  color: ${(p) => p.theme.colors.landingText};
  background-color: ${(p) => p.theme.colors.accent};
  border-color: ${(p) => p.theme.colors.accent};
  line-height: 2.2;
  letter-spacing: 1.7px;
  text-transform: uppercase;
  outline: none;
  transition: 0.15s linear;
  padding: 0 20px;
  font-size: 12px;
  border-radius: 10rem;
  min-width: 200px !important;
  margin: 8px auto;
  box-sizing: content-box;
  height: auto !important;
  ${(p) => mediaqueries.tablet`
    position: relative;
    height: inherit;
    width: inherit;

    top: 0;
    left: 0;
    border: 1px solid transparent;
    color: ${(p) => p.theme.colors.landingText};
    background: ${(p) => p.theme.colors.accent};
    font-weight: ${(p) => p.theme.fonts.weights.heavy};
    border-radius: 10rem;
    transition: 0.15s linear;

    &:hover {
      background: ${(p) => p.theme.colors.accent};
        color: ${(p) => p.theme.colors.landingText};

      border-color: ${(p) => p.theme.colors.accent};
    }
    &[disabled] {
      cursor: not-allowed;
    }
    svg * {
      fill: ${(p) => p.theme.colors.accent};
    }
  `}
`
export default BookingButton