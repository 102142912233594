/* eslint-disable no-restricted-globals */
// TYPEFORM = 'https://synchealth.typeform.com/to/K8oyPb?utm_source=xxxxx&env=xxxxx&host=xxxxx&utm_cid=xxxxx&cus=xxxx&org=xxxxx'

import { useAnalytics } from '@bestyled/contrib-analytics-google'
import React from 'react'
import * as typeformEmbed from '@typeform/embed'
import { usePortal } from '@bestyled/primitives'
import styled from 'styled-components'

import Button from './Button'
import Headings from './Headings'
import mediaqueries from '../../styles/media'
import Modal from '../Modal'


// import { graphql, useStaticQuery } from 'gatsby'

export interface TypeformProps {
  url: string
  hos?: string
  hideHeaders?: boolean
  hideFooter?: boolean
  opacity?: number
  buttonText?: string
  //  onSubmit?: Function
  children?: string
  cus?: string
  org?: string
  regtype?: string

  setVisible?: { (boolean): void }
}

const Typeform = ({ children, ...props }: TypeformProps) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { onEvent } = useAnalytics()

  const isTypeform = /typeform\.com/gi.test(props.url)

  let path

  if (typeof window !== 'undefined' && window.location) {
    path = location.href
  }

  React.useEffect(() => {
    if (location.href.indexOf('#register') !== -1) {
      if (!isOpen) {
        setIsOpen(true)
      }
    } else {
      setIsOpen(false)
    }
  }, [path])
  const iframeElement = isTypeform ? (
    <InnerTypeform visible={isOpen} {...props} />
  ) : (
    <InnerRegistration visible={isOpen} setVisible={setIsOpen} {...props} />
  )
  return (
    <Headingh6>
      <MainRegisterButton
        href='#'
        onClick={(e) => {
          e.preventDefault()
          if (history.pushState) {
            history.pushState({}, document.title, `${window && window.location ? location.href : ''}#register`)
          }
          setIsOpen(true)
          onEvent({
            category: 'register',
            action: 'start',
            label: props.url
          })
        }}
      >
        {children}
      </MainRegisterButton>
      {isOpen && iframeElement}
    </Headingh6>
  )
}

const InnerTypeform = ({
  url,
  hos: hostname = window && window.location && window.location.hostname,
  hideHeaders = false,
  hideFooter = false,

  // Widget options
  opacity = 100,
  visible = false,
  buttonText = 'Start',
  cus: customer = '',
  org: organization = 'global',
  regtype = 'self'
}: TypeformProps & { visible: boolean }) => {
  const typeformRef = usePortal('typeform')
  const { utms, onEvent } = useAnalytics()

  typeformRef.style.position = 'fixed'
  typeformRef.style.top = '0'
  typeformRef.style.left = '0'
  typeformRef.style.width = '100%'
  typeformRef.style.height = '100%'
  typeformRef.style.overflow = 'hidden%'
  typeformRef.style.opacity = '1'
  typeformRef.style.zIndex = '1024'
  typeformRef.className = `${typeformRef.className} typeform-portal-container`

  const onSubmit = () => {
    onEvent({
      category: 'register',
      action: 'submit',
      label: url
    })
    // setTimeout(() => {
    //   window.location.href = `https://${window.location.hostname}/continue-specialists`
    // }, 1000)
  }

  React.useEffect(() => {
    const options = {
      hideHeaders,
      hideFooter,
      opacity,
      buttonText,
      onSubmit
    }

    if (typeformRef) {
      typeformEmbed.makeWidget(typeformRef, url, options)
    }
  }, [])

  return null
}

const InnerRegistration = ({
  url,
  hos: hostname = window && window.location && window.location.hostname,
  hideHeaders = false,
  hideFooter = false,

  // Widget options
  opacity = 100,
  setVisible,
  visible = false,
  buttonText = 'Start',
  cus: customer = '',
  org: organization = 'global',
  regtype = 'self'
}: TypeformProps & { visible: boolean }) => {
  const typeformRef: { [x: string]: any } = {}
  const { utms, onEvent } = useAnalytics()

typeformRef.customStyles = {}
  typeformRef.customStyles.position = 'fixed'
  typeformRef.customStyles.top = '0'
  typeformRef.customStyles.left = '0'
  typeformRef.customStyles.width = '100vw'
  typeformRef.customStyles.height = '100vh'
  typeformRef.customStyles.overflow = 'hidden%'
  typeformRef.customStyles.opacity = '1'
  typeformRef.customStyles.zIndex = '1024'
  typeformRef.className = `${typeformRef.className} typeform-portal-container`

  const onSubmit = (data) => {
    onEvent({
      category: 'register',
      action: 'submit',
      label: url
    })
    setTimeout(() => {
      setVisible(false)
      window.location.href = `https://${window.location.hostname}/continue-specialists`
    }, 1000)
  }

  return <Modal show={visible} link={url} setShow={setVisible} onSubmit={onSubmit} allowFullScreen={true} {...typeformRef} />
}

const MainRegisterButton = styled.a`
  box-sizing: border-box;
  font-family: ${(p) => p.theme.fonts.serif};
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  color: ${(p) => p.theme.colors.landingText};
  background-color: ${(p) => p.theme.colors.blueButton};
  border-color: ${(p) => p.theme.colors.blueButton};
  line-height: 2.2;
  letter-spacing: 1.7px;
  text-transform: uppercase;
  outline: none;
  transition: 0.15s linear;
  padding: 7px 32px 6px;
  font-size: 12px;
  border-radius: 10rem;
  min-width: 200px !important;
  display: inline-block;
  margin-bottom: 10px;
`

var Headingh6 = styled(Headings.h6)`
  padding-bottom: 10px;
  ${mediaqueries.phone`
  display: flex;
  flex-direction: row;
  justify-content: center;`}
  ${mediaqueries.phablet_up`
  display: flex;
  flex-direction: row;
  justify-content: center;`}
  ${mediaqueries.desktop_up`
  display: flex;
  flex-direction: row;
  justify-content: left;`}
`

export default Typeform
