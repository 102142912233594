import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'
import styled from 'styled-components'

type Props = {
  show: boolean
  link: string
  setShow: { (boolean): void }
  [x: string]: any
}

const customStyles = {
  // content: {
  //   top: '50%',
  //   left: '50%',
  //   right: 'auto',
  //   bottom: 'auto',
  //   marginRight: '-50%',
  //   transform: 'translate(-50%, -50%)',
  // },
  //   overlay: {
  //     zIndex: 12,
  //     position: 'fixed',
  //     top: 0,
  //     left: 0,
  //     right: 0,
  //     bottom: 0,
  //   }
}

function SHModal({
  show = false,
  setShow = (s) => {
    show = s
  },
  customStyles,
   overlayClassName='modal-overlay',
  className='modal-style',
  link,
  ...props
}: Props) {
  const [appElement, setAppElement] = useState()
  let document = {}

  if (typeof window !== 'undefined' ) 
    document = window.document

  useEffect(() => {
    // @ts-ignore
    setAppElement(window.document.querySelector('#element-anchor'))
  }, [document])

  useEffect((): any => {
    if (props.onSubmit) {
      const listener = (event: MessageEvent<any>): void => {
        console.log(`Received Submit data: ${event.data}`)
        props.onSubmit(event.data)
      }
      window.addEventListener('message', listener, false)
      return () => {
        window.removeEventListener('message', listener)
      }
    }
  })

  if (!appElement) {
    return null
  }


  return (
    <StyledModalContainer onClick={() => setShow(false)}>
      <ReactModal
        isOpen={show}
        // @ts-ignore
        parentSelector={() => document.body}
        appElement={appElement}
        style={customStyles}
        overlayClassName={overlayClassName}
        className={className}
        onRequestClose={() => setShow(false)}
        shouldCloseOnOverlayClick={true}
      >
        <iframe
          className='embed-responsive-item'
          src={link}
          style={customStyles}
          data-target='#myModal'
          id='video'
          // @ts-ignore
          allowscriptaccess='always'
          allow='autoplay'
          allowFullScreen={true}
        ></iframe>
      </ReactModal>
    </StyledModalContainer>
  )
}

const StyledModalContainer = styled.div`
  z-index: 9;
`

export default SHModal
