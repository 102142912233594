import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AccordionItem from './AccordionItem'
import './style/main.scss'

export const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState()

  const renderedQuestionsAnswers = items.map((item, index) => {
    const showDescription = index === activeIndex ? 'show-description' : ''
    const fontWeightBold = index === activeIndex ? 'font-weight-bold' : ''
    const ariaExpanded = index === activeIndex ? 'true' : 'false'
    return (
      <AccordionItem
        showDescription={showDescription}
        fontWeightBold={fontWeightBold}
        ariaExpanded={ariaExpanded}
        item={item}
        index={index}
        key={index}
        onClick={() => {
          if (index === activeIndex) {
            return setActiveIndex((prevActiveIndex) => null)
          }
          setActiveIndex(index)
        }}
      />
    )
  })

  return (
    <div className='faq col-12'>
      <dl className='faq__list col-12'>{renderedQuestionsAnswers}</dl>
    </div>
  )
}


export default Accordion
