import * as React from 'react'
import styled from 'styled-components'

const Figure = ({ children }) => {
  return <FigureStyled>{children}</FigureStyled>
}

const FigureStyled = styled.figure`
  color: ${(p) => p.theme.colors.grey};
  font-size: 14px;
  text-align: center;
  width: 100%;
  padding-top: 6px;
  padding-bottom: 20px;
  & figcaption {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
`

export default Figure
