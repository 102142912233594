import * as React from 'react'
import Helmet from 'react-helmet'
import { useAnalytics } from '@bestyled/contrib-analytics-google'
import Button from './Button'
import { Headings } from '.'

interface ISimplePracticeInstance {
  url: string
  scopeId: string
  scopeUri: string
  applicationId: string
}

const getInstanceFromState = (state: string) => {
  switch (state) {
    case 'CA':
    case 'WA':
    case 'HI':
    case 'OR':
      return 'synctalk'
    case 'TN':
    case 'US':
    default:
      return 'synchronoushealth'
  }
}

const SP_INSTANCE: { [key: string]: ISimplePracticeInstance } = {
  synchronoushealth: {
    url: 'https://synchronoushealth.clientsecure.me',
    scopeId: '0150ad85-de9b-4eab-abcd-4e87397ab470',
    scopeUri: 'synchronoushealth',
    applicationId:
      '7c72cb9f9a9b913654bb89d6c7b4e71a77911b30192051da35384b4d0c6d505b'
  },
  synctalk: {
    url: 'https://synctalk.clientsecure.me',
    scopeId: '47c4a1cb-d72f-4bb1-8d97-fe5f4cbcc523',
    scopeUri: 'synctalk',
    applicationId:
      '7c72cb9f9a9b913654bb89d6c7b4e71a77911b30192051da35384b4d0c6d505b'
  }
}

const SimplePracticeButton: React.FC<{ name: string; state: string }> = ({
  state,
  name,
  children
}) => {
  const spData = SP_INSTANCE[getInstanceFromState(state)]
  const { onEvent } = useAnalytics()
  const onClick = (e) => {
    onEvent({
      category: 'specialist',
      action: 'appointment',
      label: name
    })
    return true
  }
  return (
    <>
      <Helmet>
        <script src="https://widget-cdn.simplepractice.com/assets/integration-1.0.js" />
      </Helmet>
      <Headings.h6>
        <Button
          width={300}
          mt={30}
          href={spData.url}
          onClick={onClick}
          className="spwidget-button"
          data-spwidget-scope-id={spData.scopeId}
          data-spwidget-scope-uri={spData.scopeUri}
          data-spwidget-application-id={spData.applicationId}
          data-spwidget-scope-global
          data-spwidget-autobind
        >
          {children}
        </Button>
      </Headings.h6>
    </>
  )
}

export default SimplePracticeButton
