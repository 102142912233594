import * as React from 'react'
import styled from 'styled-components'
import mediaqueries from '../../styles/media'
import Headings from './Headings'

const FigureInset = ({ right, children }) => {
  const items = React.Children.toArray(children) as any[]

  const heading = items.find((item) => item.props.mdxType.startsWith('h')).props
    .children
  const caption = items.find((item) => item.props.mdxType === 'figcaption')
    .props.children
  const paras = items.filter((item) => item.props.mdxType === 'p')
  const rest = items.filter(
    (item) =>
      item.props.mdxType !== 'p' &&
      item.props.mdxType !== 'figcaption' &&
      !item.props.mdxType.startsWith('h')
  )

  return (
    <FigureContainer right={right}>
      <FigureImage>
        <FigureImageInset right={right}>{paras}</FigureImageInset>
      </FigureImage>
      <FigureCaption right={right}>
        <Headings.h5>{heading}</Headings.h5>
        <Figcaption>{caption}</Figcaption>
        <FigureRest>{rest}</FigureRest>
      </FigureCaption>
    </FigureContainer>
  )
}

const FigureContainer = styled.figure<{ right: boolean }>`
  position: relative;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
  flex-direction: ${(p) => (p.right ? 'row-reverse' : 'row')};

  ${mediaqueries.tablet`
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    `}
`

const FigureImage = styled.div`
  display: inline-block;
  width: 50%;
  & p {
    max-width: 100%;
  }

  ${mediaqueries.tablet`
     width: 75%;
     right: 0;
     left: 0;
     padding: 0;
     margin-left: auto;
     margin-right: auto;
  `}

  ${mediaqueries.phablet`
    width: 85%;
    right: 0;
    left: 0;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    `}
`

const FigureRest = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  font-size: 12px;

  & h6 {
    width: auto !important;
  }

  ${mediaqueries.tablet`
  padding-bottom: 0;
`}
`

const FigureImageInset = styled.div<{ right: boolean }>`
  width: 150%;
  position: relative;
  ${(p) => p.right && `right: 50%; padding-right: 40px;`}
  ${(p) => !p.right && `left: 0%; padding-left: 40px;`}
  & p {
    max-width: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }

  ${mediaqueries.desktop_up`
    top: 50%;
    transform: translateY(-50%);
    `}

  ${mediaqueries.tablet`
     width: 100%;
     right: 0;
     left: 0;
     padding: 0;
  `}
`

const FigureCaption = styled.div<{ right: boolean }>`
  ${(p) => p.right && `margin-left: 40px;`}
  ${(p) => !p.right && `margin-right: 40px;`}
  width: 50%;
  z-index: 1;
  margin-top: auto;
  margin-bottom: auto;
  background: ${(p) => p.theme.colors.background};
  opacity: 95%;
  border: 0.5px solid ${(p) => p.theme.colors.grey};
  padding-top: 20px;
  padding-bottom: 0px;
  border-radius: 2px;

  ${mediaqueries.tablet`
     margin-top: 0;
     margin-bottom: 0;
     margin-left: auto;
     margin-right: auto;
     top: -40px;
     position: relative;
  `}

  ${mediaqueries.phablet`
    width: 70%
  `}
`

const Figcaption = styled.figcaption`
  color: ${(p) => p.theme.colors.grey};
  font-size: 14px;
  text-align: center;
  width: 100%;
  padding-top: 6px;
  padding-bottom: 20px;
  line-height: 2em;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
`

export default FigureInset
