import * as React from 'react'
import styled, { keyframes } from 'styled-components'

interface CSSFadeInProps {
  as?: string
}

const fadein = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const Transition = styled.div`
  opacity: 0;
  animation: ${fadein} 0.3s linear forwards;
`

const CSSFadeIn: React.FC<CSSFadeInProps> = ({ as, children }) => {
  return <Transition as={as as any}>{children}</Transition>
}

export default {
  CSS: {
    FadeIn: CSSFadeIn
  }
}
