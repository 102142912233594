import * as React from 'react'
import CarouselNuka from 'nuka-carousel'
import { DefaultTheme, useTheme } from 'styled-components'

export default (props) => {
  const theme: DefaultTheme & {colors?: any} = useTheme()
  const [slideIndex, setSlideIndex] = React.useState(0)
  React.useEffect(() => {
    setTimeout(() => setSlideIndex(props.children.length - 1), 2000)
  }, [props.children])
  return (
    <CarouselNuka
      enableKeyboardControls
      cellAlign={'center'}
      slideIndex={slideIndex}
      afterSlide={ (slideIndex) => setSlideIndex(slideIndex) }
      autoplay={true}
      heightMode={'max'}
      initialSlideHeight={400}
      wrapAround
      
      defaultControlsConfig={{
        nextButtonText: '>',
        prevButtonText: '<',
        prevButtonStyle: {
          backgroundColor: theme.colors.brand
        },
        nextButtonStyle: {
          backgroundColor: theme.colors.brand
        },
        pagingDotsStyle: {
          fill: theme.colors.brand,
          paddingLeft: '10px'
        }
      }}
      {...props}
    />
  )
}
